<template>
    <v-dialog v-model="passPhraseDialog" persistent max-width="600" overlay-opacity=".8">
        <v-card class="pb-1">
            <v-card-title class="headline">
                {{ $t("card.type-passphrase") }}
            </v-card-title>
            <v-card-text>
                <form @submit.prevent="emitComment">


                    <v-row>
                        <v-col cols="12" v-html="submitOptions ? submitOptions.commentDesc : ''">
                        </v-col>
                        <v-col cols="12">

                            <v-text-field id="passPhrase" v-model="passPhrase" :error-messages="passPhraseErrors"
                                :counter="100" label="Passphrase" required v-bind:type="passType"
                                v-on:input="$v.passPhrase.$touch()" v-on:blur="$v.passPhrase.$touch()"
                                v-on:keyup.enter="submit">
                                <v-tooltip slot="append" bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn icon color="primary" v-on="on" v-bind="attrs" v-on:click="togglePass">
                                            <v-icon v-if="passType === 'password'">{{
                                            iconEye
                                            }}</v-icon>
                                            <v-icon v-else>{{ iconEyeClose }}</v-icon>
                                        </v-btn>
                                    </template>
                                    <span v-if="passType === 'password'">{{
                                    $t("tooltip.show-pass")
                                    }}</span>
                                    <span v-else>{{ $t("tooltip.hide-pass") }}</span>
                                </v-tooltip>
                            </v-text-field>


                        </v-col>
                    </v-row>
                </form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-2" text @click="cancelSelect">
                    <v-icon class="mr-1">{{ iconCancel }}</v-icon>
                    {{ $t("button.cancel") }}
                </v-btn>

                <v-btn color="blue darken-1" text @click="emitComment" v-if="submitOptions">
                    <v-icon class="mr-1">{{ submitOptions.btnIcon }}</v-icon>
                    {{ $t(submitOptions.btnText) }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import {
    mdiAlertCircleOutline, mdiSend, mdiCloseCircleOutline, mdiEye, mdiEyeOff,
} from "@mdi/js";
import { validationMixin } from "vuelidate";
const { required, minLength, maxLength } = require("vuelidate/lib/validators");

export default {
    name: "insert-passphrase",
    props: {
        showPassPhraseDialog: { type: Boolean },
        submitOptions: { type: Object },
    },
    data: () => ({
        passPhrase: null,
        iconAlert: mdiAlertCircleOutline,
        iconSubmit: mdiSend,
        iconCancel: mdiCloseCircleOutline,
        passType: "password",
        iconEye: mdiEye,
        iconEyeClose: mdiEyeOff,
    }),
    mixins: [validationMixin],
    validations: {
        passPhrase: { required, minLength: minLength(8), maxLength: maxLength(50) },
    },

    computed: {
        passPhraseDialog: {
            get() {
                return this.showPassPhraseDialog;
            },
            set(val) {
                this.$emit("showHidePassPhraseDialog", val);
            },
        },
        passPhraseErrors() {
            const errors = [];
            if (!this.$v.passPhrase.$dirty) return errors;
            !this.$v.passPhrase.maxLength &&
                errors.push(
                    `${this.$t("error.passphrase-max-length")} 50 ${this.$t(
                        "text.characters"
                    )}`
                );
            !this.$v.passPhrase.minLength &&
                errors.push(
                    `${this.$t("error.passphrase-min-length")} 8 ${this.$t(
                        "text.characters"
                    )}`
                );
            !this.$v.passPhrase.required &&
                errors.push(`${this.$t("error.passphrase-must-be-filled")}`);
            return errors;
        },
    },
    watch: {
        passPhraseDialog(val) {
            if (!val) return;
            setTimeout(() => {
                document.getElementById("passPhrase").focus();
            }, 500)
        }
    },
    methods: {
        emitComment() {
            this.$v.$touch();
            if (this.$v.$invalid) return;

            this.$emit("passPhraseFilled", this.passPhrase);
            this.passPhraseDialog = false;

            setTimeout(() => {
                this.passPhrase = null;
                this.$v.$reset();
            }, 500);
        },
        cancelSelect() {
            this.passPhrase = null;
            this.$v.$reset();
            this.$emit("passPhraseFilled", null);
            this.passPhraseDialog = false;
        },
        togglePass() {
            this.passType = this.passType === "password" ? "text" : "password";
        },
    },
};
</script>